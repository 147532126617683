import React from "react";
import { Box, Image, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import Title1BG from "../images/Common/title1BG.webp";
import { useTheme } from "../utils/themeContext";

const TitleOne = ({ data }) => {
  const { theme } = useTheme();
  // 用 useBreakpointValue 根據螢幕寬度調整top
  const topValue = useBreakpointValue({ base: "78%", md: "65%", lg: "66%" });
  const spacingValue = useBreakpointValue({ base: 4, md: 4, lg: 8 });
  const fontSizeValue = useBreakpointValue({
    base: "24px",
    md: "28px",
    lg: "36px",
  });
  const heightValue = useBreakpointValue({
    base: "230px",
    md: "230px",
    lg: "230px",
  });

  if (!data || !data.data) return null;
  const { tag3_title, tag4_memo } = data.data;

  //把取到的主題色轉成rgba格式
  const rgbaColor = (hexColor, alpha) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  const bgColor = rgbaColor(theme.colors.primary[500], 1);

  return (
    <Box
      position="relative"
      width="100%"
      maxWidth="600px"
      margin="auto"
      marginTop="60px"
    >
      {/* <Image
        src={Title1BG}
        alt="Title background"
        width="100%"
        marginLeft="5%"
      /> */}
      <VStack
        // position="absolute"
        // top={topValue} // 根據螢幕寬度調整top
        // left="50%"
        // transform="translate(-50%, -50%)"
        // spacing={spacingValue}
        align="center"
        width="100%"
        height={heightValue}
      >
        <Box
          width="100%"
          background={`linear-gradient(90deg, 
            rgba(0,0,0,0) 0%, 
            ${bgColor} 20%, 
            ${bgColor} 80%, 
            rgba(0,0,0,0) 100%)`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          //backgroundColor={bgColor}
          padding="10px"
        >
          <Text
            textAlign="center"
            fontSize={fontSizeValue}
            fontWeight="bold"
            color="gold"
            textShadow="0 0 5px rgba(0,0,0,0.5)"
          >
            {tag3_title}
          </Text>
        </Box>
        <Box
          dangerouslySetInnerHTML={{ __html: tag4_memo }}
          color="white"
          textAlign="center"
          fontSize="18px"
          maxWidth="80%"
          overflowY="auto"
        />
        {/* <Text fontSize="14px" color="white" textAlign="center" maxWidth="80%">
          Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho
          tro ngan tang
        </Text> */}
      </VStack>
    </Box>
  );
};

export default TitleOne;
