import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";

// 輔助函數：將十六進制顏色轉換為 RGB
const hexToRgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

// 輔助函數：將 RGB 轉換為十六進制顏色
const rgbToHex = (r, g, b) => {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

// 輔助函數：計算對比色
const calculateContrastColor = (hex) => {
  const [r, g, b] = hexToRgb(hex);
  return rgbToHex(255 - r, 255 - g, 255 - b);
};

// 輔助函數：計算灰階顏色
const calculateGrayscale = (hex) => {
  const [r, g, b] = hexToRgb(hex);
  const gray = Math.round(0.299 * r + 0.587 * g + 0.114 * b);
  return rgbToHex(gray, gray, gray);
};

// 生成色票
const generateColorPalette = (frontColor) => {
  const [r, g, b] = hexToRgb(frontColor);
  const palette = {};

  // 設置 500 為 frontColor
  palette[500] = frontColor;

  // 生成 400 到 100（變淺）
  for (let i = 1; i <= 4; i++) {
    const factor = i / 4;
    const newR = Math.round(r + (255 - r) * factor);
    const newG = Math.round(g + (255 - g) * factor);
    const newB = Math.round(b + (255 - b) * factor);
    palette[500 - i * 100] = rgbToHex(newR, newG, newB);
  }

  // 生成 600 到 900（變深）
  for (let i = 1; i <= 4; i++) {
    const factor = i / 4;
    const newR = Math.round(r * (1 - factor));
    const newG = Math.round(g * (1 - factor));
    const newB = Math.round(b * (1 - factor));
    palette[500 + i * 100] = rgbToHex(newR, newG, newB);
  }

  return palette;
};

// 生成對比色票
const generateContrastPalette = (frontColor) => {
  const contrastColor = calculateContrastColor(frontColor);
  return generateColorPalette(contrastColor);
};

// 生成灰階色票
const generateGrayscalePalette = (frontColor) => {
  const palette = {};
  const [r, g, b] = hexToRgb(frontColor);

  for (let i = 0; i < 10; i++) {
    const factor = i / 9;
    const gray = Math.round(((r + g + b) / 3) * factor + 255 * (1 - factor));
    palette[900 - i * 100] = rgbToHex(gray, gray, gray);
  }

  return palette;
};

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [frontColor, setFrontColor] = useState("#222"); // 默認顏色

  const colorPalette = useMemo(() => {
    console.log("Generating color palette for:", frontColor);
    return generateColorPalette(frontColor);
  }, [frontColor]);

  const contrastPalette = useMemo(
    () => generateContrastPalette(frontColor),
    [frontColor]
  );
  const grayscalePalette = useMemo(
    () => generateGrayscalePalette(frontColor),
    [frontColor]
  );

  const updateFrontColor = (colorString) => {
    console.log("Updating frontColor, received:", colorString);
    if (colorString && colorString.startsWith("front_color:")) {
      const newColor = colorString.split(":")[1];
      console.log("Setting new frontColor:", newColor);
      setFrontColor(newColor);
    } else {
      console.log("Setting frontColor directly:", colorString || "#222");
      setFrontColor(colorString || "#222");
    }
  };

  const theme = useMemo(() => {
    console.log("Updating theme with new color palette");
    return {
      colors: {
        primary: colorPalette,
        contrast: contrastPalette,
        grayscale: grayscalePalette,
      },
    };
  }, [colorPalette, contrastPalette, grayscalePalette]);

  useEffect(() => {
    console.log("更新body背景顏色為:", frontColor);
    document.body.style.backgroundColor = frontColor;
  }, [frontColor]);

  return (
    <ThemeContext.Provider value={{ frontColor, updateFrontColor, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
