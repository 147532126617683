import React from "react";
import { VStack, Link, Image, Box } from "@chakra-ui/react";
import fb from "../images/Common/fb.webp";
import telegram from "../images/Common/telegram.webp";
import zalo from "../images/Common/zalo.webp";
import { useTheme } from "../utils/themeContext";
import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { SiZalo } from "react-icons/si";

const SocialMediaButtons = ({ data }) => {
  const { theme, frontColor } = useTheme();
  if (!data || !data.data) return null;

  const { tag8_link0, tag8_link1, tag8_link2 } = data.data;

  const buttons = [
    {
      icon: FaTelegramPlane,
      link: tag8_link0,
      alt: "Telegram",
    },
    {
      icon: FaFacebookF,
      link: tag8_link1,
      alt: "Facebook",
    },
    {
      icon: SiZalo,
      link: tag8_link2,
      alt: "Zalo",
    },
  ];

  const contrastColor = theme.colors.contrast[500];

  const iconStyle = {
    backgroundColor: contrastColor,
    color: "white",
    borderRadius: "50%",
    padding: "7px",
    fontSize: "40px",
    marginRight: "10px",
    boxShadow: `0 0 10px ${theme.colors.primary[500]}`,
    transition: "all 0.3s ease",
    filter: "drop-shadow(2px 0 2px rgba(0, 0, 0, 0.5))",
    _hover: {
      transform: "scale(1.1)",
      boxShadow: `0 0 15px ${theme.colors.primary[500]}`,
    },
  };

  return (
    <VStack
      position="fixed"
      bottom="100px"
      right="20px"
      spacing={3}
      zIndex={1000}
      willChange="transform" //告訴瀏覽器這個的變化不會影響到其他元素，這樣瀏覽器就可以對該元素進行硬件加速，從而提高性能。
    >
      {buttons.map(
        (button, index) =>
          button.link && (
            <Link href={button.link} isExternal key={index}>
              <Box as={button.icon} {...iconStyle} title={button.alt} />
            </Link>
          )
      )}
    </VStack>
  );
};

export default SocialMediaButtons;
