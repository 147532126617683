import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import { Box } from "@chakra-ui/layout";
import bg from "../images/Common/bg.webp";
import MiddleButton from "../components/MiddleButton";
import TitleOne from "../components/TitleOne";
import BlockOne from "../components/BlockOne";
import BlockTwo from "../components/BlockTwo";
import HotGames from "../components/HotGames";
import Footer from "../components/Footer";
import SocialMediaButtons from "../components/SocialMediaButtons";
import { useLocation } from "react-router-dom";
import { fetchData } from "../api/base_api";
import { useTheme } from "../utils/themeContext";
import { useTheme as useChakraTheme } from "@chakra-ui/react";

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const { updateFrontColor, theme, frontColor } = useTheme();

  useEffect(() => {
    console.log("Current frontColor:", frontColor);
    console.log("Current theme.colors.primary:", theme.colors.primary);
    console.log(
      "Current theme.colors.primary[900]:",
      theme.colors.primary[900]
    );
  }, [frontColor, theme]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("q") || "admin";
        const result = await fetchData(code);
        setData(result);

        // 更新 frontColor
        if (result.data && result.data.front_color) {
          console.log("Updating frontColor to:", result.data.front_color);
          updateFrontColor(result.data.front_color);
        } else {
          console.log("Using default color", "#222");
          updateFrontColor("front_color:#222"); // 使用默認顏色
        }
      } catch (err) {
        setError("無法取得資料");
        console.error("取得資料錯誤:", err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [location, updateFrontColor]);

  useEffect(() => {
    console.log("Current frontColor:", frontColor);
    console.log(
      "Current theme.colors.primary[900]:",
      theme.colors.primary[900]
    );
  }, [frontColor, theme]);

  if (loading) return <Box>載入中...</Box>;
  if (error) return <Box>錯誤: {error}</Box>;
  if (!data) return <Box>沒有資料</Box>;

  return (
    <Box
      position="relative"
      minHeight="100vh"
      width={{ base: "100%", md: "90%", lg: "80%", xl: "50%" }}
      margin="auto"
      // backgroundImage={`url(${bg})`}
      // backgroundSize="100% auto"
      // backgroundRepeat="repeat-y"
      // backgroundPosition="top center"
      backgroundColor={theme.colors.primary[900]}
    >
      <Box position="absolute" top="0" left="0" right="0" bottom="0" />
      <Box position="relative" zIndex="1">
        <Carousel data={data} />
        <MiddleButton data={data} />
        <TitleOne data={data} />
        <BlockOne data={data} />
        <BlockTwo data={data} />
        <HotGames data={data} />
        <Footer data={data} />
        <SocialMediaButtons data={data} />
      </Box>
    </Box>
  );
};

export default Home;
