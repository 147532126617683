import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Link, Text } from "@chakra-ui/layout";
import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import buttonOrange from "../images/Common/buttonBG.webp";
import LazyImage from "./lazyImage";
import { useTheme } from "../utils/themeContext";

const MiddleButton = ({ data }) => {
  const { updateFrontColor, theme, frontColor } = useTheme();
  if (!data || !data.data) return null;

  const { tag2_button_text, tag2_button_link, hash } = data.data;

  const topColor = "#FF9D4D";
  const bottomColor = "#FFD700";

  return (
    <Box
      position="relative"
      marginTop="-100px"
      top="80%"
      left="50%"
      transform="translate(-50%, 20%)"
      textAlign="center"
      zIndex="1"
    >
      <Image
        src={stylesConfig.mainLogoOrigin}
        alt="911 Logo"
        mb={4}
        maxWidth="200px"
        mx="auto"
      />
      {/* <Box
        as={Link}
        href={tag2_button_link}
        display="inline-block"
        position="relative"
        cursor="pointer"
        isExternal
      >
        <Image src={buttonOrange} alt={tag2_button_text} maxWidth="250px" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="white"
          fontSize="lg"
          fontWeight="bold"
        >
          {tag2_button_text}
        </Box>
      </Box> */}
      <Box
        as={Link}
        href={tag2_button_link}
        display="inline-block"
        position="relative"
        cursor="pointer"
        isExternal
        textDecoration="none"
        _hover={{ textDecoration: "none" }}
      >
        <Box
          as="button"
          position="relative"
          padding="12px 46px"
          margin="10px 0"
          color="white"
          fontSize="lg"
          fontWeight="bold"
          textShadow="0 0 0px #fff, 0 0 2px #fff, 0 0 21px #fff"
          border="none"
          borderRadius="full"
          background={`linear-gradient(to bottom, ${topColor}, ${bottomColor})`}
          boxShadow={`0 0 5px ${topColor}, 0 0 25px ${topColor}`}
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(to bottom, ${topColor}, ${bottomColor}, ${topColor})`,
            backgroundSize: "100% 200%",
            borderRadius: "full",
            filter: "blur(5px)",
            zIndex: -1,
            transition: "0.5s",
          }}
          _hover={{
            boxShadow: `0 0 5px ${topColor}, 0 0 25px ${topColor}, 0 0 50px ${topColor}, 0 0 100px ${topColor}`,
            _before: {
              backgroundPosition: "0 100%",
            },
          }}
        >
          <Text>{tag2_button_text}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default MiddleButton;
