import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  SimpleGrid,
  Link,
  VStack,
  keyframes,
} from "@chakra-ui/react";
import block3 from "../images/Common/block3.webp";
import grass from "../images/Common/grass.webp";
import sampleGame from "../images/Common/sampleGame.webp";
import Title1BG from "../images/Common/title1BG.webp";
import LazyImage from "./lazyImage";
import { useTheme } from "../utils/themeContext";

// 旋轉動畫
const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

const HotGames = ({ data }) => {
  const { theme } = useTheme();

  if (!data || !data.data) return null;

  const { hash, tag7_pic } = data.data;

  //把取到的主題色轉成rgba格式
  const rgbaColor = (hexColor, alpha) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  const bgColor = rgbaColor(theme.colors.primary[500], 1);

  // 根據tag7_pic的項目個數決定區域高度
  const boxHeight =
    tag7_pic && tag7_pic.length <= 3
      ? { base: "400px", md: "450px", lg: "750px" }
      : { base: "650px", md: "700px", lg: "880px" };

  return (
    <Box
      // backgroundImage={`url(${grass})`}
      // backgroundSize="contain"
      // backgroundRepeat="repeat"
      padding="20px"
      paddingTop="0"
      marginTop="100px"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      position="relative"
      _after={{
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "100px",
        backgroundImage:
          "linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))",
        pointerEvents: "none",
      }}
    >
      {/* <Box position="relative" width="100%" maxWidth="600px" margin="auto">
        <Image
          src={Title1BG}
          alt="Title background"
          width="100%"
          marginLeft="5%"
        />
        <VStack
          position="absolute"
          top="43%"
          left="50%"
          transform="translate(-50%, -50%)"
          spacing={4}
          align="center"
          width="100%"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="gold"
            textShadow="0 0 5px rgba(0,0,0,0.5)"
          >
            HOT GAMES
          </Text>
        </VStack>
      </Box> */}
      <Box
        // backgroundImage={`url(${block3})`}
        // backgroundRepeat="no-repeat"
        // backgroundSize="100% 100%"
        width="100%"
        //height={boxHeight}
        position="relative"
        marginBottom="60px"
      >
        <Box
          width="100%"
          background={`linear-gradient(90deg, 
            rgba(0,0,0,0) 0%, 
            ${bgColor} 20%, 
            ${bgColor} 80%, 
            rgba(0,0,0,0) 100%)`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          //backgroundColor={bgColor}
          padding="10px"
        >
          <Text
            color="gold"
            fontSize={{ base: "20px", md: "26px", lg: "36px" }}
            fontWeight="bold"
            textAlign="center"
            // position="absolute"
            // top={{ base: "45px", md: "48px", lg: "85px" }}
            // left="50%"
            // transform="translateX(-45%)"
          >
            HOT GAMES
          </Text>
        </Box>

        <Flex
          direction="column"
          height="100%"
          justifyContent="flex-start"
          borderRadius="10px"
          padding="10px"
          marginTop={{ base: "20px", md: "20px", lg: "50px" }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 1, lg: 1 }}
            spacing={2}
            justifyItems="center"
          >
            {tag7_pic &&
              tag7_pic.map((game, index) => (
                <Link
                  key={index}
                  href={game.url || "#"}
                  isExternal={!!game.url}
                >
                  <LazyImage
                    src={`/UserFiles/${hash}/${game.pic}`}
                    alt="911win"
                    w={{ base: "100%", md: "100%", lg: "100%" }}
                    h="auto"
                    objectFit="cover"
                  />
                </Link>
              ))}
          </SimpleGrid>
          {/* {gameCategories.map((category, index) => (
            <Box key={index} marginTop="5px">
              <Text
                color="white"
                fontSize={{ base: "16px", md: "20px", lg: "20px" }}
                fontWeight="bold"
                textAlign="center"
                mb={2}
              >
                {category.title}
              </Text>
              <SimpleGrid
                columns={{ base: 3, md: 3, lg: 4 }}
                spacing={2}
                justifyItems="center"
              >
                {category.games.map((game) => (
                  <Link key={game.id} href={game.link} isExternal>
                    <LazyImage
                      src={game.image}
                      alt={`Game ${game.id}`}
                      w={{ base: "80px", md: "80px", lg: "120px" }}
                      h="auto"
                      objectFit="cover"
                    />
                  </Link>
                ))}
              </SimpleGrid>
            </Box>
          ))}
            */}
        </Flex>
      </Box>
    </Box>
  );
};

export default HotGames;
