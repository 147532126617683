import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  ChakraProvider,
  ColorModeScript,
  FormLabel,
  extendTheme,
} from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import history from "./redux/history";
import { Route, Routes, useLocation } from "react-router-dom";
import { skeletonTheme } from "./chakraTheme/components/skeleton";
import { buttonTheme } from "./chakraTheme/components/button";
import { spinnerTheme } from "./chakraTheme/components/spinner";
import { inputTheme } from "./chakraTheme/components/input";
import { selectTheme } from "./chakraTheme/components/select";
import { checkboxTheme } from "./chakraTheme/components/checkbox";
import { numberInputTheme } from "./chakraTheme/components/inputNumber";
import { progressTheme } from "./chakraTheme/components/progress";
import { modalTheme } from "./chakraTheme/components/modal";
import { popoverTheme } from "./chakraTheme/components/popover";
import { ThemeProvider, useTheme } from "./utils/themeContext";

const ChakraTheme = ({ children }) => {
  const { theme } = useTheme();
  const customTheme = useMemo(() => {
    console.log("Updating Chakra theme with:", theme);
    return extendTheme({
      ...theme,
      components: {
        Skeleton: skeletonTheme,
        Button: buttonTheme,
        Spinner: spinnerTheme,
        Input: inputTheme,
        Select: selectTheme,
        Checkbox: checkboxTheme,
        NumberInput: numberInputTheme,
        Progress: progressTheme,
        Modal: modalTheme,
        Popover: popoverTheme,
      },
    });
  }, [theme]);
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const routerSelector = (state) => state.navigator;

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <ReduxRouter history={history} routerSelector={routerSelector}>
        <ChakraTheme>
          <ColorModeScript />
          <App />
        </ChakraTheme>
      </ReduxRouter>
    </Provider>
  </ThemeProvider>
);

reportWebVitals();
