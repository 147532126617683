import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import { useTheme } from "../utils/themeContext";

const BlockTwo = ({ data }) => {
  const { theme } = useTheme();

  if (!data || !data.data) return null;

  const { tag6_memo } = data.data;

  return (
    <Box
      position="relative"
      width="90%"
      maxWidth="600px"
      margin="auto"
      marginTop="50px"
      height="400px"
      padding="10px"
    >
      {/* 左上和右下框 */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="10px"
        bottom="10px"
        border={`2px solid ${theme.colors.primary[500]}`}
        zIndex={1}
      />
      {/* 右上和左下框 */}
      <Box
        position="absolute"
        top="10px"
        left="-10px"
        right="0px"
        bottom="20px"
        border={`2px solid ${theme.colors.primary[500]}`}
        zIndex={2}
      />
      <Box
        position="relative"
        height="100%"
        //backgroundColor="white"
        zIndex={3}
        padding="20px"
      >
        <VStack spacing={2} overflow="hidden" textAlign="center" height="100%">
          <Box
            dangerouslySetInnerHTML={{ __html: tag6_memo }}
            color="white"
            wordBreak="break-word"
            overflowWrap="break-word"
            height="100%"
            fontSize="18px"
            overflowY="auto"
          />
        </VStack>
      </Box>
    </Box>
  );
};

export default BlockTwo;
